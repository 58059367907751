import { Injectable } from '@angular/core';
import { UsersService } from '@app/modules/accounts/services/users.service';
import { BarSelectorService } from '@app/shared/services/bar-selector.service';
import { AuthService } from 'services/auth-service/auth.service';
import { CustomDateServiceService } from 'services/custom-date-service/custom-date-service.service';

@Injectable()
export class WorkingContextService {
  constructor(
    private identitySerivce: UsersService,
    private customDateServiceService: CustomDateServiceService,
    private authService: AuthService,
    private barSelectorService: BarSelectorService
    ){}

  public load(){
    return new Promise((resolve,reject) => {
   
      if (this.authService.isAuthenticated()) {
        this.identitySerivce.getMyAccountInfo().subscribe(e => {
          this.customDateServiceService.setLocale(e.locale)
          if(!e.fromOldPortal){
            this.barSelectorService.setAccounts(e.userAccounts, e.activeUserAccount);
          }
          resolve(true);
        })
      }else {
        resolve(true);
      }

    })
  }
}
